/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/alex.jpeg";
import team2 from "assets/images/abner.gif";
import team3 from "assets/images/chiaro.PNG";
import team4 from "assets/images/luis.jpeg";

function Palestrantes() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Palestrantes
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Conheça nossos palestrantes que compartilharão conhecimentos e tendências atuais da
              informática e tecnologia.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Alex Dias Camargo"
                position={{ color: "info", label: "Startup" }}
                description="Alex Camargo é Gestor de Inovação e Tecnologia no Programa Startup Lab de Inovação Aberta da Secretaria de Inovação do RS, executado pelo UALL. Ex-professor de Computação no IFSUL - Campus Bagé, idealiza as startups Bíblia-IA, Proby*, e o Ecossistema de Inovação de Bagé - Ecobah."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team3}
                name="Pablo de Chiaro Rosa"
                position={{ color: "info", label: "IA" }}
                description="Pablo é doutorando em Ciência da Computação (UFPel) com foco em sinais visuais e inteligência artificial. Mestre em Ciência da Computação, com mais de 12 anos de experiência em desenvolvimento web, gerenciamento de projetos e infraestrutura de redes."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Abner Gilead Araujo Guedes"
                position={{ color: "info", label: "Gamificação" }}
                description="Abner Guedes é graduado em Informática, especialista em Gráfica Digital e mestre em Computação Aplicada. Atua em Programação Web, Análise de Sistemas, Processamento Gráfico, e desenvolve projetos de Inteligência Artificial e jogos para agropecuária, saúde, educação e inclusão. É, também, Tradutor inglês-português e português-inglês."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team4}
                name="Luis Brudna"
                position={{ color: "info", label: "IA" }}
                description="Luis Brudna possui graduação em Química pela Universidade Federal do Rio Grande do Sul (1997), mestrado e doutorado em Química pela Universidade Federal do Rio Grande do Sul (2000/2005). Realizou pós-doutorado em eletroquímica na França pela Universite de Paris XI, em 2007."
              />
            </MKBox>
          </Grid>
          {/*
          <Grid item xs={12} lg={12}>
            <MKTypography variant="h2" color="info">
              Em Breve ....
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Emma Roberts"
                position={{ color: "info", label: "UI Designer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="William Pearce"
                position={{ color: "info", label: "Boss" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Ivana Flow"
                position={{ color: "info", label: "Athlete" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Marquez Garcia"
                position={{ color: "info", label: "JS Developer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid>
          */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Palestrantes;
