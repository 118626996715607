/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
//import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import ifsulLogo from "assets/images/ifsul-logo.png";
import comLogo from "assets/images/com1.png";
import gleiderLogo from "assets/images/gleider.jpg";
import yazigiLogo from "assets/images/yazigi.png";
//import facebookLogo from "assets/images/logos/gray-logos/logo-facebook.svg";
//import nasaLogo from "assets/images/logos/gray-logos/logo-nasa.svg";
//import vodafoneLogo from "assets/images/logos/gray-logos/logo-vodafone.svg";
//import digitalOceanLogo from "assets/images/logos/gray-logos/logo-digitalocean.svg";

function Apoio() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography color="info" variant="h2">
            Apoio
          </MKTypography>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={6} md={3} lg={3}>
            <Link href="https://www.ifsul.edu.br" target="_blank" rel="noopener noreferrer">
              <MKBox component="img" src={ifsulLogo} alt="IFSul" width="100%" opacity={0.6} />
            </Link>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <Link href="https://cominformatica.com/" target="_blank" rel="noopener noreferrer">
              <MKBox
                component="img"
                src={comLogo}
                alt=".com Informática"
                width="70%"
                opacity={0.6}
              />
            </Link>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <Link
              href="https://www.instagram.com/produtoragleider/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MKBox
                component="img"
                src={gleiderLogo}
                alt="Gleider Produtora"
                width="70%"
                opacity={0.6}
              />
            </Link>
          </Grid>
          <Grid item xs={6} md={3} lg={3}>
            <Link href="https://www.yazigi.com.br/bage" target="_blank" rel="noopener noreferrer">
              <MKBox component="img" src={yazigiLogo} alt="Yazigi" width="70%" opacity={0.6} />
            </Link>
          </Grid>
          {/*<Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={facebookLogo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={nasaLogo} alt="Nasa" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={vodafoneLogo} alt="Vodafone" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={digitalOceanLogo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid>*/}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Apoio;
