/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Informacoes() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Precisamos da
                    <br />
                    Sua Ajuda
                  </>
                }
                description="Sua opinião é muito importante para nós! Queremos que a Semana Acadêmica de Informática seja relevante e interessante para todos os participantes."
              />
              <RotatingCardBack
                image={bgBack}
                title="Colabore pelo Formulário"
                description="Em breve, disponibilizaremos um formulário para avaliação das atividades da Semana Acadêmica. Sua opinião será fundamental para aprimorarmos ainda mais o evento!"
                action={{
                  type: "external",
                  route: "",
                  label: "Formulário",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="groups"
                  title="Palestras"
                  description="Participe de palestras com especialistas em diversas áreas da informática, explorando as últimas tendências e inovações tecnológicas. Além disso, você pode colaborar sugerindo temas que gostaria de ver discutidos."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="co_present"
                  title="Minicursos Práticos"
                  description="Aprenda novas habilidades e técnicas nos nossos 10 minicursos focados em diferentes aspectos da informática. Não perca a chance de contribuir com sugestões de temas que mais lhe interessam."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="workspace_premium"
                  title="Competições de Programação"
                  description="Teste suas habilidades nas nossas competições de programação e concorra a prêmios incríveis. Este é o momento perfeito para mostrar seu talento e se divertir!"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="sports_esports"
                  title="Dia Nerd"
                  description="Celebre a cultura geek com atividades especiais no Dia Nerd. Teremos jogos, concursos de cosplay e muito mais para você aproveitar e se divertir com amigos e colegas."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Informacoes;
