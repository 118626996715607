/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import camiseta from "assets/images/produtos/Camiseta.png";
import bottom from "assets/images/produtos/Botton.png";
import caneca from "assets/images/produtos/Caneca.png";

export default [
  {
    image: camiseta,
    name: "Camiseta",
  },
  {
    image: caneca,
    name: "Caneca",
  },
  {
    image: bottom,
    name: "Boton",
  },
];
