/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
//import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Contadores from "pages/Presentation/sections/Contadores.js";
import Informacoes from "pages/Presentation/sections/Informacoes";
import Produtos from "pages/Presentation/sections/Produtos";
import Apoio from "pages/Presentation/sections/Apoio";
//import Download from "pages/Presentation/sections/Download";
import Palestrantes from "./sections/Palestrantes";

// Presentation page components
//import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import fotos from "./sections/data/carrossel";

// Images
//import bgImage from "assets/images/bg-presentation.jpg";
import bgImage1 from "assets/images/SACI1.png";
import Cronograma from "./sections/Cronograma";
import Carrossel from "./sections/Carrossel";

import dadosCronograma from "./sections/data/cronograma";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.even3.com.br/saciifsul2024/",
          label: "Inscreva-se",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage1})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={10} justifyContent="center" mx="auto">
            <MKTypography
              textAlign="center"
              variant="h1"
              color="white"
              mt={4}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Semana Acadêmica do Curso de Informática{" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Participe da Semana Acadêmica do Curso de Informática no IFSul Campus Bagé, de 11 a 13
              de setembro de 2024. Inscreva-se e venha explorar o futuro da tecnologia!
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Contadores />
        <Palestrantes />
        <Informacoes />
        <Produtos />
        <Cronograma cronograma={dadosCronograma} />
        <Carrossel fotos={fotos} />
        <Apoio />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
