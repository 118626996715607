/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
//import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import MKButton from "components/MKButton";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/produtos";

function Produtos() {
  const renderData = data.map(({ image, name }) => (
    <Grid item xs={12} md={4} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
    </Grid>
  ));

  return (
    <MKBox component="section" py={-6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Produtos"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography color="info" variant="h2" fontWeight="bold">
            Produtos do evento
          </MKTypography>
          <MKTypography variant="body1" mt={2} mb={2} color="text">
            Não perca a chance de levar uma lembrança da nossa Semana Acadêmica de Informática!
            Estaremos vendendo camisetas, canecas e botons exclusivos do evento. Garanta já os seus
            e participe dessa experiência única!
          </MKTypography>
          {/*
          <MKButton
            variant="contained"
            color="info"
            href="https://docs.google.com/forms/d/e/1FAIpQLSewt1tlU0LM_pMbYeWhesDr3oDMK_G10CFHQ0teeo61ssQnyw/viewform"
          >
            Se tem interesse, preencha o formulário!
          </MKButton>
          */}
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          {/*<Grid item xs={12} lg={12} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>*/}
          {renderData}
          {/*</Grid>*/}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Produtos;
