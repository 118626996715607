import React from "react";
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style/Carrossel.css";

import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import { Grid } from "@mui/material";

const Carrossel = ({ fotos }) => {
  return (
    <MKBox component="section" py={0}>
      <Container>
        <Grid container item justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
          <MKTypography color="info" variant="h2">
            Fotos
          </MKTypography>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
          <Carousel
            showArrows={true}
            infiniteLoop={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={true}
            interval={3000}
          >
            {fotos.map((foto, index) => (
              <div key={index}>
                <img src={foto.url} alt={foto.alt} />
                {foto.caption && <p className="legend">{foto.caption}</p>}
              </div>
            ))}
          </Carousel>
        </Grid>
      </Container>
    </MKBox>
  );
};

Carrossel.propTypes = {
  fotos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      caption: PropTypes.string,
    })
  ).isRequired,
};

export default Carrossel;
