import abertura from "assets/images/saci2019/abertura";
import cafe from "assets/images/saci2019/cafe";
import jogos from "assets/images/saci2019/jogos";
import jogos1 from "assets/images/saci2019/jogos1";
import jogos2 from "assets/images/saci2019/jogos2";
import minicurso from "assets/images/saci2019/minicurso";
import palestra from "assets/images/saci2019/palestra";

const fotos = [
  { url: abertura, alt: "Abertura da SACI 2019", caption: "Abertura da SACI 2019" },
  { url: cafe, alt: "Coffee Break - SACI 2019", caption: "Coffee Break - SACI 2019" },
  { url: jogos, alt: "Dia Nerd - SACI 2019", caption: "Dia Nerd - SACI 2019" },
  { url: jogos1, alt: "Dia Nerd - SACI 2019", caption: "Dia Nerd - SACI 2019" },
  { url: jogos2, alt: "Dia Nerd - SACI 2019", caption: "Dia Nerd - SACI 2019" },
  { url: minicurso, alt: "Minicurso - SACI 2019", caption: "Minicurso - SACI 2019" },
  { url: palestra, alt: "Palestra - SACI 2019", caption: "Palestra - SACI 2019" },
];

export default fotos;
