import { Grid } from "@mui/material";
import React from "react";

import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import MKBox from "components/MKBox";
import PropTypes from "prop-types";

import "./style/Cronograma.css";

const Cronograma = ({ cronograma }) => {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          flexDirection="column"
          alignItems="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography color="info" variant="h2">
            Cronograma
          </MKTypography>
          <MKTypography variant="body1" mt={2} mb={2} color="text">
            O cronograma pode sofrer alterações devido a agenda dos palestrantes e ministrantes.
          </MKTypography>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container justifyContent="center" sx={{ mx: "auto", textAlign: "center" }}>
          {cronograma.map((daySchedule, index) => (
            <div className="cronograma-day" key={index}>
              <h3>{daySchedule.day}</h3>
              <table className="cronograma-table">
                <thead>
                  <tr>
                    <th className="time-column">Horário</th>
                    <th>Atividade</th>
                  </tr>
                </thead>
                <tbody>
                  {daySchedule.events.map((event, idx) => (
                    <tr key={idx}>
                      <td className="time-column">{event.time}</td>
                      <td>{event.activity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
};

Cronograma.propTypes = {
  cronograma: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      events: PropTypes.arrayOf(
        PropTypes.shape({
          time: PropTypes.string.isRequired,
          activity: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default Cronograma;
