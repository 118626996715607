const cronograma = [
  {
    day: "11 de Setembro",
    events: [
      {
        time: "07:45 - 08:30",
        activity: "Credenciamento",
      },
      {
        time: "08:30 - 09:00",
        activity: "Abertura e entrega do tema da competição de inovação",
      },
      {
        time: "09:00 - 10:30",
        activity:
          "Roda de conversa sobre cursos superiores em computação da região de Bagé com: Thales Vaz (IFSul), Sandro Camargo (Unipampa) e Paula Lemos Silveira (Urcamp) mediado por Anderson Ritta",
      },
      {
        time: "10:30 - 11:00",
        activity: "Coffee Break",
      },
      {
        time: "11:00 - 12:30",
        activity: "Programa Startup Lab de Inovação Aberta com: Alex Dias Camargo",
      },
      {
        time: "13:30 - 14:00",
        activity: "Credenciamento",
      },
      {
        time: "14:00 - 15:30",
        activity: "Inteligência Artificial: A revolução com: Luis Brudna",
      },
      {
        time: "15:30 - 16:00",
        activity: "Coffee Break",
      },
      {
        time: "16:00 - 17:00",
        activity: "Futuro do Trabalho com inteligência artificial com: Pablo de Chiaro da Rosa",
      },
      {
        time: "17:00 - 18:15",
        activity: "Princípios da gamificação com: Abner Guedes",
      },
    ],
  },
  {
    day: "12 de Setembro",
    events: [
      {
        time: "07:45 - 08:00",
        activity: "Credenciamento",
      },
      {
        time: "08:00 - 10:00",
        activity: "Minicursos",
      },
      {
        time: "10:00 - 10:30",
        activity: "Coffee Break",
      },
      {
        time: "10:30 - 12:30",
        activity: "Minicursos",
      },
      {
        time: "13:30 - 14:00",
        activity: "Credenciamento",
      },
      {
        time: "14:00 - 16:00",
        activity: "Minicursos",
      },
      {
        time: "16:00 - 16:30",
        activity: "Coffee Break",
      },
      {
        time: "16:30 - 18:15",
        activity: "Minicursos",
      },
    ],
  },
  {
    day: "13 de Setembro",
    events: [
      {
        time: "07:45 - 08:00",
        activity: "Credenciamento",
      },
      {
        time: "08:00 - 10:00",
        activity: "Jogos digitais, tabuleiro, RPG e competição de programação",
      },
      {
        time: "10:00 - 10:30",
        activity: "Coffee Break",
      },
      {
        time: "10:30 - 12:30",
        activity: "Jogos digitais, tabuleiro, RPG e competição de programação",
      },
      {
        time: "13:30 - 14:00",
        activity: "Credenciamento",
      },
      {
        time: "14:00 - 16:00",
        activity: "Jogos digitais e de tabuleiro e competição de truco, counter strike e cosplay",
      },
      {
        time: "16:00 - 16:30",
        activity: "Coffee Break",
      },
      {
        time: "16:30 - 17:30",
        activity: "Jogos digitais e de tabuleiro e competição de truco, counter strike e cosplay",
      },
      {
        time: "17:30 - 18:15",
        activity: "Encerramento e premiações",
      },
    ],
  },
];

export default cronograma;
